import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useNewAdAccountRequestStore = defineStore({
  id: 'newAdAccountRequest',
  state: () => ({
    countList: {},
    moderationComments: [],
  }),
  actions: {
    async getAdAccountRequests(filters = {}) {
      const {data} = await axios.get('ad-account-requests', {
        params: filters,
      })

      return data
    },

    async getAdAccountRequestsCounts(filters = {}) {
      const {data} = await axios.get('ad-account-requests/counts', {
        params: {...filters},
      })

      this.countList = data

      return data
    },

    async getAdAccountRequestHistory(payload) {
      const {data} = await axios.get(`ad-account-requests/history`, {params: payload})

      return data
    },

    async getRequestComments(payload) {
      const {data} = await axios.get('ad-account-requests/request-comments', {params: payload})

      return data
    },
    async setRequestModerationComments(comments) {
      this.moderationComments = comments
    },
    async addRequestComment(payload) {
      const {data} = await axios.post('ad-account-requests/request-comments', payload)

      return data
    },
    async updateRequestComment(id, payload) {
      const {data} = await axios.patch(`ad-account-requests/request-comments/${id}`, payload)

      return data
    },
    async deleteRequestComment(id) {
      const {data} = await axios.delete(`ad-account-requests/request-comments/${id}`)

      return data
    },

    async changeResponsible(payload) {
      const {data} = await axios.post('ad-account-requests/change-responsible', payload)

      return data
    },

    async changeCompletedBy(payload) {
      const {data} = await axios.post('ad-account-requests/change-completed-by', payload)

      return data
    },

    async getAdAccounts(params) {
      const {data} = await axios.get('ad-accounts', {params})

      return data
    },
  },
})
