<script setup>
import {onMounted, defineEmits, ref, defineProps, defineExpose} from 'vue'
import {openUrl} from '@/plugins/helpers'

// Props & emits
let emit = defineEmits(['file-updated', 'delete-file', 'changed'])

const props = defineProps({
  activeRequestFields: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

let requestFormData = new FormData()
let uploadedFileName = ref('')
const defaultFormData = {
  reason: 'new_application',
  legal_name: '',
  country_of_business: '',
  address_of_business: '',
  email: '',
  website_url: '',
  registry_or_database_link: '',
  description: '',
}
let formData = ref(structuredClone(defaultFormData))

// Methods
onMounted(() => {
  init()
})

const init = () => {
  if (props.activeRequestFields) {
    formData.value = Object.assign(formData.value, props.activeRequestFields)
  }
}

const resetForm = () => {
  formData.value = structuredClone(defaultFormData)
}
const onFileUploaded = (event = null, file_type) => {
  if (!event.target.files[0]) {
    return
  }

  requestFormData.set(`fields[${file_type}]`, event.target.files[0], event.target.files[0].name)
  uploadedFileName.value = event.target.files[0].name
  updateFormPayload()
}

const deleteUploadedFile = item => {
  requestFormData.delete(item)

  uploadedFileName.value = ''

  emit('delete-file', item)
}

const updateFormPayload = () => {
  emit('file-updated', requestFormData)
}

const onChange = () => {
  emit('changed', {fields: formData.value})
}

defineExpose({resetForm})
</script>

<template>
  <div class="form-group">
    <div class="alert alert-light form-group" role="alert">
      <label class="mb-2">Reason for this application *</label>
      <div class="form-check mb-1">
        <input
          v-model="formData.reason"
          @change="onChange"
          :disabled="disabled"
          class="form-check-input"
          type="radio"
          name="crypto-wl-reason"
          id="crypto-wl-reason-1"
          value="new_application"
        />
        <label class="form-check-label" for="crypto-wl-reason-1"
          >New application: I am applying for the first time</label
        >
      </div>
      <div class="form-check">
        <input
          v-model="formData.reason"
          @change="onChange"
          :disabled="disabled"
          class="form-check-input"
          type="radio"
          name="crypto-wl-reason"
          id="crypto-wl-reason-2"
          value="add_another_one"
        />
        <label class="form-check-label" for="crypto-wl-reason-2"
          >Add another ad account: I have previously received approval to advertise cryptocurrency from one of my ad
          accounts, but I need to apply for another ad account
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col pe-0">
      <label for="crypto-wl-legal_name">Registered business/legal name *</label>
      <div class="input-group">
        <input
          v-model="formData.legal_name"
          @change="onChange"
          class="form-control"
          :disabled="disabled"
          id="crypto-wl-legal_name"
          required
        />
      </div>
    </div>
    <div class="col">
      <label for="crypto-wl-country_of_business">Country of business *</label>
      <div class="input-group">
        <input
          v-model="formData.country_of_business"
          @change="onChange"
          class="form-control"
          id="crypto-wl-country_of_business"
          :disabled="disabled"
          required
        />
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="crypto-wl-address_of_business">Business address </label>
    <div class="input-group">
      <textarea
        v-model="formData.address_of_business"
        @change="onChange"
        class="form-control"
        id="crypto-wl-address_of_business"
        rows="2"
        :disabled="disabled"
        required
      />
    </div>
    <span class="small text-muted mt-1"> Country or region, Town / City, Street, Floor and Postal code </span>
  </div>
  <div class="form-group">
    <label for="crypto-wl-email">Email address * </label>
    <div class="input-group">
      <input
        v-model="formData.email"
        @change="onChange"
        class="form-control"
        id="crypto-wl-email"
        type="email"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="crypto-wl-website_url">Website URL * </label>
    <div class="input-group">
      <input
        v-model="formData.website_url"
        @change="onChange"
        class="form-control"
        id="crypto-wl-website_url"
        type="url"
        :disabled="disabled"
        required
      />
    </div>
  </div>
  <div class="form-group">
    <label for="crypto-wl-documentation-file-upload"
      >Please provide documentation
      <small class="text-muted">
        (e.g. copy of license, screenshot of confirmation of registration) of any required licenses or regulatory
        certifications. Please ensure that the official document you upload is in English. If it is not in English,
        please upload a notarized official translation in English along with the original document.
      </small>
    </label>
    <div v-if="formData?.documentation_url">
      <span @click="openUrl(formData?.documentation_url)" class="full-width btn btn-outline-success">Open</span>
    </div>
    <div v-else>
      <template v-if="!uploadedFileName">
        <template v-if="disabled">
          <div class="alert alert-info">No file</div>
        </template>
        <template v-else>
          <label for="crypto-wl-documentation-file-upload" class="full-width btn btn-secondary">
            <i class="fa fa-upload"></i> Add file
          </label>

          <input
            class="d-none"
            @change="onFileUploaded($event, 'documentation')"
            id="crypto-wl-documentation-file-upload"
            :disabled="disabled"
            type="file"
          />
        </template>
      </template>
      <template v-else>
        <span class="full-width btn btn-outline-success"
          >{{ uploadedFileName }}
          <button type="button" class="btn-close circle-btn" @click="deleteUploadedFile('documentation')" />
        </span>
      </template>
    </div>
  </div>

  <div class="form-group">
    <label for="crypto-wl-registry_or_database_link"
      >Please also provide a link to the public registry or database that can be used to verify the license or
      regulatory certification (if you have it).
    </label>
    <div class="input-group">
      <input
        v-model="formData.registry_or_database_link"
        @change="onChange"
        class="form-control"
        placeholder="Link"
        id="crypto-wl-registry_or_database_link"
        :disabled="disabled"
        type="url"
      />
    </div>
  </div>
  <div class="form-group">
    <label for="crypto-wl-description"
      >Please provide a brief, general description of the products, services, or other cryptocurrency-related content
      you intend to promote. *
    </label>
    <div class="input-group">
      <input
        v-model="formData.description"
        @change="onChange"
        class="form-control"
        placeholder="Your answer"
        id="crypto-wl-description"
        :disabled="disabled"
        required
      />
    </div>
  </div>
</template>
