import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useOtherRequestStore = defineStore({
  id: 'OtherRequest',
  state: () => ({}),
  actions: {
    async getOtherRequests(filters = {}) {
      const {data} = await axios.get('other-requests', {
        params: filters,
      })

      return data
    },
    async createOtherRequest(payload) {
      const {data} = await axios.post('other-requests', payload)

      return data
    },
    async getOtherRequest(hash, filters = {}) {
      const {data} = await axios.get(`other-requests/${hash}`, {
        params: filters,
      })

      return data
    },
  },
})
