import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useUserStore = defineStore('userStore', {
  id: 'userStore',
  state: () => ({
    users: [],
    userManagerInfo: null,
  }),
  getters: {
    coreUsersOptions: state => {
      return state.users.filter(n => n.policies.is_core_team).map(({hash, name}) => ({value: hash, label: name}))
    },
    usersOptions: state => {
      return state.users.map(({id, name}) => ({value: id, label: name}))
    },
    employeesOptions: state => {
      return state.users.map(({hash, name}) => ({value: hash, label: name}))
    },
    coreUsersWithEmptyOptions: state => {
      let result = state.users.filter(n => n.policies.is_core_team).map(({id, name}) => ({value: id, label: name}))

      result.unshift({value: 0, label: 'Without manager'})

      return result
    },
  },
  actions: {
    async loadEmployees(filters = {}) {
      const {data} = await axios.get('users/get-employees', {params: filters})

      this.users = data

      return data
    },

    async getImpersonationEmployees() {
      const {data} = await axios.get('users/get-impersonation-employees')
      return data
    },

    async getOnboardingManagersOptions() {
      const {data} = await axios.get('users/get-onboarding-managers')
      return data
    },

    async getAgencyAssistanceOptions() {
      const {data} = await axios.get('users/get-agency-assistances')
      return data
    },

    async getUserNotifications() {
      const {data} = await axios.get('/users/notifications')

      return data
    },

    async updateUserNotifications(payload) {
      const {data} = await axios.patch('/users/notifications', payload)

      return data
    },

    async getUsers(filters) {
      const {data} = await axios.get('users', {params: filters})

      return data
    },

    async createUser(payload) {
      const {data} = await axios.post('users', payload)

      return data
    },

    async updateUserSettings(payload) {
      const {data} = await axios.patch(`users/update-settings/`, payload)

      return data
    },

    async updateUser(hash, payload) {
      const {data} = await axios.patch(`users/${hash}/`, payload)

      return data
    },

    async resendUserInvitation(hash) {
      const {data} = await axios.post(`/users/${hash}/resend-invitation`)

      return data
    },

    async removeUser(hash) {
      const {data} = await axios.delete(`/users/${hash}`)

      return data
    },

    async getUserBalance() {
      const {data} = await axios.get('balance-info')

      return data
    },
    async getUserManager() {
      const {data} = await axios.get('manager-info')

      this.userManagerInfo = data

      return data
    },
    async getUserAccountSummary() {
      const {data} = await axios.get('ad-accounts-summary')

      return data
    },
    async getUserRequests(filters = {}) {
      const {data} = await axios.get('my-requests', {params: filters})

      return data
    },
    async getUserDisplayNotifications(filters = {}) {
      const {data} = await axios.get('get-notifications', {params: filters})

      return data
    },
  },
})
