<script setup>
import {defineProps, defineModel, computed, ref, defineEmits} from 'vue'
import Multiselect from '@vueform/multiselect'

const emit = defineEmits(['focusout', 'search-change'])
const modelValue = defineModel({required: true})

const props = defineProps({
  id: {type: String, default: 'the-select-multiple'},
  disabled: {type: Boolean, default: false},
  options: {type: Array, required: true},
  required: {type: Boolean, default: false},
  mode: {type: String, default: 'tags'},
  searchable: {type: Boolean, default: true},
  allow_clear: {type: Boolean, default: true},
  show_no_results: {type: Boolean, default: true},
  can_deselect: {type: Boolean, default: true},
  create_option: {type: Boolean, default: false},
  placeholder: {type: String},
  show_options: {type: Boolean, default: true},
  canAddTags: {type: Boolean, default: false},
})

const inputValue = ref('')

const selectMode = computed(() => (props.mode === 'tags' ? 'tags' : 'multiple'))
const isValid = computed(() => !props.required || modelValue.value.length > 0)

const addTag = async (newTag = null) => {
  if (!props.canAddTags) {
    return
  }

  const tag = (newTag || inputValue.value).trim()
  if (tag && !modelValue.value.includes(tag)) {
    modelValue.value.push(tag)
  }
  inputValue.value = ''
}
const handleSearchChange = value => {
  emit('search-change', value)

  if (!props.canAddTags) {
    return
  }

  inputValue.value = value
}
const handleFocusOut = async () => {
  emit('focusout')

  if (!props.canAddTags) {
    return
  }

  if (selectMode.value === 'tags' && inputValue.value.trim()) {
    await addTag()
  }
}
</script>

<template>
  <div :class="['the-select-wrapper', selectMode === 'tags' ? 'tags' : '', isValid ? 'valid' : 'invalid']">
    <input v-if="required && !isValid" class="d-none" required />
    <Multiselect
      v-model="modelValue"
      :options="props.options"
      :id="id"
      :mode="selectMode"
      :disabled="props.disabled"
      :placeholder="placeholder"
      :searchable="searchable"
      :canDeselect="can_deselect"
      :required="required"
      :create-option="create_option"
      :showNoResults="show_no_results"
      :showOptions="show_options"
      @tag="addTag"
      @search-change="handleSearchChange"
      @focusout="handleFocusOut"
    >
      <template v-if="!allow_clear" #clear>
        <span />
      </template>
    </Multiselect>
  </div>
</template>
