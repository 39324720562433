<script setup>
import {defineProps, defineEmits, ref, computed, onMounted, defineExpose, watch} from 'vue'
import Multiselect from '@vueform/multiselect'
import {debounce} from '@/helpers/debounce'
import {useNewAdAccountRequestStore} from '@/views/ad-account-requests/AdAccountRequestStore'
import {useNotification} from '@kyvg/vue3-notification'
import {isEqual} from 'lodash'
import {formatBingToMicrosoftLowercase} from '@/plugins/helpers'

const {notify} = useNotification()
const {getAdAccounts} = useNewAdAccountRequestStore()

// Emits & props
const emit = defineEmits(['changed', 'update:modelValue', 'focusout', 'select-option'])
const props = defineProps({
  modelValue: {},
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Enter from 2 characters to search',
  },
  listBySearch: {
    type: Boolean,
    default: false,
  },
  payload: {
    type: Object,
    default: null,
  },
  valueToReturn: {
    type: String,
    default: 'id',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
})

// Refs
const adAccounts = ref([])
const loading = ref(false)
const mode = ref('single')
const fullSearchResultList = ref([])
let previousPayload = null
const publicPath = process.env.BASE_URL

// Computed
const isValid = computed(() => {
  if (props.multiple === true) {
    return !props.required || props.modelValue.length !== 0
  }

  return !props.required || !!props.modelValue
})

const adAccountsOptions = computed(() => {
  return adAccounts.value.map(({id, title, external_id, can_has_top_up, platform}) => {
    let disabled = props.payload?.with_can_has_top_up && !can_has_top_up

    return {
      value: props.valueToReturn === 'id' ? id : external_id,
      label: title,
      sublabel: external_id,
      is_disabled: disabled,
      icon: `${publicPath}images/platforms/${formatBingToMicrosoftLowercase(platform)}-logo.svg`,
    }
  })
})

// Methods
onMounted(async () => {
  await init()
})

const init = async () => {
  if (props.multiple === true) {
    mode.value = 'tags'
  }

  if (props.listBySearch) {
    await requestGetAdAccounts()
  }
}

const requestGetAdAccounts = async search => {
  try {
    adAccounts.value = await getAdAccounts({search, ...props.payload, with: ['company']})

    if (search === '  ' && fullSearchResultList.value.length === 0) {
      fullSearchResultList.value = structuredClone(adAccounts.value)
    }
  } catch (responseError) {
    notify({type: 'error', text: responseError.data.message})
  } finally {
    loading.value = false
  }
}

const handleSearch = value => {
  if ((value === '  ' || value === '') && fullSearchResultList.value.length > 0) {
    adAccounts.value = structuredClone(fullSearchResultList.value)

    return
  }

  checkSearchValue(value)
}

const checkSearchValue = async searchValue => {
  if (searchValue.length >= 2) {
    await reloadAdAccounts(searchValue)
  }
}

const reloadAdAccounts = debounce(async searchValue => {
  loading.value = true

  await requestGetAdAccounts(searchValue)
}, 300)

const onChange = value => {
  emit('update:modelValue', value)
  emit('changed', structuredClone(adAccounts.value.find(n => n.id === value)))
}

const onFocusOut = () => {
  emit('focusout')
  handleSearch('')
}

const getActiveOption = (value, option) => {
  emit('select-option', option)
}

watch(
  () => props.payload,
  newPayload => {
    if (!isEqual(newPayload, previousPayload)) {
      previousPayload = structuredClone(newPayload)
      fullSearchResultList.value.length = 0
    }
  },
  {deep: true},
)

defineExpose({handleSearch})
</script>

<template>
  <div :class="['the-select-wrapper', isValid ? 'valid' : 'invalid', mode === 'tags' ? 'tags' : '']">
    <input v-if="required && !isValid" class="d-none" required />
    <Multiselect
      :value="modelValue"
      ref="multiSelect"
      :options="adAccountsOptions"
      @select="getActiveOption"
      :disabled="disabled"
      :track-by="['sublabel', 'label']"
      label="label"
      :mode="mode"
      searchable
      :placeholder="listBySearch ? 'Select ad account' : placeholder"
      :loading="loading"
      @input="onChange"
      @search-change="handleSearch"
      @focusout="onFocusOut"
    >
      <template #clear><span /></template>

      <template #singlelabel="{value}">
        <div class="multiselect-single-label">
          <img class="character-label-icon max-w-20 me-2" :src="value.icon" /> {{ value.label }}
        </div>
      </template>
      <template #option="props"
        ><img class="character-label-icon max-w-20 me-2" :src="props.option.icon" :alt="props.option.icon" />
        <div>
          <div>{{ props.option.label }}</div>
          <small v-if="props.option.sublabel" class="text-muted">{{ props.option.sublabel }}</small>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
