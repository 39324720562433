<script setup>
import {defineProps, ref, onMounted, defineEmits} from 'vue'
import TheModal from '@/components/ui/TheModal.vue'
import BaseForm from '@/components/BaseForm.vue'
import AlertDanger from '@/components/ui/AlertDanger.vue'
import {useOnboardingStore} from '@/views/onboarding/OnboardingStore'

const {setClientClickupTask} = useOnboardingStore()

// Props & emits
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})
const emit = defineEmits(['submitted', 'hidden'])
const modalId = props.id ?? 'onboarding-compliance-check-clickup-modal'

// Refs
const theModal = ref()
const clickup_task_url = ref('')

// Methods
onMounted(() => {
  openModal()
})

const openModal = () => {
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}
const handleSubmit = () => {
  setClientClickupTask(clickup_task_url.value)

  emit('submitted')
  closeModal()
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal ref="theModal" @hidden="emitHidden" :id="modalId" title="Сompliance Сheck">
    <base-form @on-submitted="handleSubmit">
      <div class="modal-body">
        <div class="form-group">
          <label>Link to Client Pre-Moderation ClickUp Task *</label>
          <input v-model="clickup_task_url" class="form-control" type="url" required />
        </div>
        <div class="form-group">
          <alert-danger>
            Customer compliance verification is mandatory. Add a link to the click up task from the
            <a
              href="https://app.clickup.com/4620077/v/b/6-901201998197-2"
              target="_blank"
              rel="nofollow noopener noreferrer"
              >Client Pre-Moderation</a
            >
            project. If your client has not yet been checked by compliance - create a new request in "<a
              href="https://my-agency.point2web.com/tasks/agency-general"
              target="_blank"
              rel="nofollow noopener noreferrer"
              >Client Pre-Moderation</a
            >".
          </alert-danger>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button class="btn btn-primary">Submit</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </base-form>
  </TheModal>
</template>
