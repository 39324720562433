<script setup>
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import {mainRouter} from '@/main'
import ImpersonationUserSelect from '@/components/local/ImpersonationUserSelect.vue'
import {isDev, isLocal} from '@/plugins/helpers'
import TheCard from '@/components/TheCard.vue'
import RequestsDropdown from '@/components/ui/dropdowns/RequestsDropdown.vue'

const {logoutWithRequest} = useAuthenticationStore()
const {user, isAuthenticated, isDeveloper, isAgency, isAgencyClientAdmin, isCoreTeam} = storeToRefs(
  useAuthenticationStore(),
)

// Methods
const handleLogout = async () => {
  await logoutWithRequest()

  await mainRouter.push({name: 'auth.login'})
}
</script>

<template>
  <the-card class="px-3" lg-border-radius>
    <div class="d-flex justify-content-between align-items-center">
      <span class="fs-16-px">
        Hello<template v-if="user.name"
          >, <span class="fw-bold">{{ user.name }}</span>
        </template>
      </span>

      <div class="d-flex align-items-center" v-if="isAuthenticated">
        <requests-dropdown class="me-2" />

        <impersonation-user-select v-if="isLocal() || isDev() || isDeveloper || isAgency" />

        <div class="dropdown">
          <button id="dropdownUserButton" aria-expanded="false" class="btn" data-bs-toggle="dropdown" type="button">
            <i class="fas fa-user"></i>
          </button>
          <ul aria-labelledby="dropdownUserButton" class="dropdown-menu">
            <li>
              <router-link :to="{name: 'user'}" class="dropdown-item">My Profile</router-link>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <template v-if="!isCoreTeam && isAgencyClientAdmin">
              <li>
                <router-link :to="{name: 'user.settings'}" class="dropdown-item">Users</router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
            </template>
            <li>
              <button @click="handleLogout" class="dropdown-item">Log out</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </the-card>
</template>
