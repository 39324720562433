import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useCompanyBillingStore = defineStore({
  id: 'companyBillingStore',
  actions: {
    async getQuickBooksCustomers(company_hash) {
      const {data} = await axios.get('quick-books-customers', {params: {company_hash}})

      return data
    },

    // Invoices

    async getInvoiceInfo(id, params) {
      const {data} = await axios.get(`company-invoices/${id}`, {params})

      return data
    },

    async getInvoices(filters) {
      const {data} = await axios.get('company-invoices', {params: filters})

      return data
    },

    async getInvoicesSummary(params) {
      const {data} = await axios.get('company-invoices/summary', {params})

      return data
    },

    async createInvoice(payload, params) {
      const {data} = await axios.post('company-invoices', payload, {params})

      return data
    },

    async updateInvoice(payload) {
      const {data} = await axios.patch(`company-invoices/${payload.id}`, payload)

      return data
    },

    async destroyInvoice(id) {
      const {data} = await axios.delete(`company-invoices/${id}`)

      return data
    },

    // Payments

    async getPaymentsSummary(params) {
      const {data} = await axios.get('company-payments/summary', {params})

      return data
    },

    async confirmPayment(hash, payload) {
      const {data} = await axios.patch(`company-payments/${hash}/confirm`, payload)

      return data
    },

    async createPayment(payload, params) {
      const {data} = await axios.post(`company-payments`, payload, {params})

      return data
    },

    async getPaymentInfo(id, params) {
      const {data} = await axios.get(`company-payments/${id}`, {params})

      return data
    },

    async getPayments(filters = {}) {
      const {data} = await axios.get('company-payments', {params: filters})

      return data
    },

    async destroyPayment(id) {
      const {data} = await axios.delete(`company-payments/${id}`)

      return data
    },

    async createOverdraft(payload) {
      const {data} = await axios.post(`overdraft-requests`, payload)

      return data
    },
    async getOverdraftRequests(payload = {}) {
      const {data} = await axios.get(`overdraft-requests`, {params: payload})

      return data
    },
  },
})
