import {defineStore} from 'pinia'
import axios from '@/plugins/axios'

export const useOnboardingStore = defineStore({
  id: 'onboardingStore',
  state: () => ({
    loading: false,
    error: null,
    clientsLocal: [],
    clientClickupTask: '',
  }),
  getters: {},
  actions: {
    setClientClickupTask(task) {
      this.clientClickupTask = task
    },

    async getClients(params = {}) {
      const {data} = await axios.get('onboarding-clients', {params: params})

      this.clientsLocal = data
      return data
    },
    async getDocument(hash) {
      const {data} = await axios.get('onboarding-document/' + hash)

      return data
    },
    async createOnboardingClient(payload) {
      const {data} = await axios.post('onboarding-clients', payload)

      return data
    },
    async updateOnboardingClient(hash, payload) {
      const {data} = await axios.patch(`onboarding-clients/${hash}`, payload)

      return data
    },
    async sendQuestioner(hash) {
      const {data} = await axios.post(`onboarding-clients/${hash}/send-questioner`)

      return data
    },
    async saveAnnex(hash, payload) {
      const {data} = await axios.post(`onboarding-clients/${hash}/save-annex`, payload)

      return data
    },
    async sendAnnex(hash, payload = {}) {
      const {data} = await axios.post(`onboarding-clients/${hash}/send-annex`, payload)

      return data
    },
    async changeOnboardingClientStatus(hash, status, status_message) {
      const {data} = await axios.post(`onboarding-clients/${hash}/change-status`, {status, status_message})

      return data
    },
    async changeStatusToClientTransfer(hash, payload, params = {}) {
      const {data} = await axios.post(`onboarding-clients/${hash}/change-status`, payload, {params})

      return data
    },
  },
})
